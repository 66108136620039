import React, {useState, useRef, useEffect} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Input, Select, Button, Modal, Col, Form, Row} from 'antd';
import type {InputRef} from 'antd';
import {selectorItemsApi} from '../apiCalls/selectorItemsApi';
import {SelectorItem, SelectorItems} from '../apiCalls/selectorItems';

const SelectComponent: React.FC<{
  onChange?: (value: string) => void;
  title: string;
  defaultValue: {name: string; id: string} | string;
  endpoint?: string;
  postEndpoint?: string;
  postExtraValues?: any;
  canDoPostRequest?: boolean;
}> = ({onChange, endpoint, postEndpoint, postExtraValues = {}, title, defaultValue, canDoPostRequest = true}) => {
  const [items, setItems] = useState<SelectorItems>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [initialValue, setInitialValue] = useState<string | undefined>(undefined);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    if (typeof defaultValue === 'string') {
      setInitialValue(defaultValue);
    } else if (defaultValue?.name) {
      setInitialValue(defaultValue.name);
    }
  }, [items]);

  const fetchItems = async () => {
    if (!endpoint) return;

    setIsLoading(true);

    try {
      const data = await selectorItemsApi.getItemList(endpoint);

      setItems(data);
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (canDoPostRequest) {
        const usedPostEndpoint = postEndpoint || endpoint;

        if (!usedPostEndpoint) {
          throw new Error('There is no valid endpoint to POST request.');
        }

        setConfirmLoading(true);
        await selectorItemsApi.setSelectItemList(usedPostEndpoint, {...postExtraValues, name: values.name});
        onChange && onChange(values.name);
        setIsModalVisible(false);
        fetchItems();
      }
    } catch (errors) {
      console.log(errors);
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (isLoading) {
    return <div></div>;
  }

  return (
    <div>
      <Modal
        confirmLoading={confirmLoading}
        title={`Add new ${title}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item label="Name" name="name" rules={[{required: true, message: 'Please input name'}]}>
                <Input placeholder="Enter name" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {initialValue ? (
        <Select
          placeholder="Elegir opción"
          onChange={onChange}
          dropdownRender={(menu) => {
            if (!canDoPostRequest) {
              return <div key={`option-${menu}`}>{menu}</div>;
            }

            return (
              <div key={`option-${menu}`}>
                {menu}
                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                  Add item
                </Button>
              </div>
            );
          }}
          defaultValue={initialValue}
          options={items.map((item: SelectorItem) => ({label: item.name, value: item.id ? item.id : item.name}))}
        />
      ) : (
        <Select
          placeholder="Elegir opción"
          onChange={onChange}
          dropdownRender={(menu) => {
            if (!canDoPostRequest) {
              return <div key={`option-${menu}`}>{menu}</div>;
            }

            return (
              <div key={`option-${menu}`}>
                {menu}
                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                  Add item
                </Button>
              </div>
            );
          }}
          options={items.map((item: SelectorItem) => ({label: item.name, value: item.id ? item.id : item.name}))}
        />
      )}
    </div>
  );
};

export default SelectComponent;
