import React, { useState } from 'react';
import { Form, Input, Button, notification, Row, Col, Select } from 'antd';
import { activesApi } from '../apiCalls/activesApi';

const { Option } = Select;

const AddActiveForm: React.FC = () => {
  const [vrnUnit, setVrnUnit] = useState('mg');
  const [ulUnit, setUlUnit] = useState('mg/day');

  const onFinish = async (values: any) => {
    const activeData = {
      ...values,
      vrn: values.vrn ? Number(values.vrn) : null,
      vrnUnit,
      ul: values.ul ? Number(values.ul) : null,
      ulUnit,
    };

    const result = await activesApi.createActive(activeData);
    if (result) {
      notification.success({
        message: 'Active Created',
        description: 'The active has been created successfully.',
        duration: 3,
      });
    } else {
      notification.error({
        message: 'Error al Crear',
        description: 'Hubo un error al crear el activo.',
        duration: 3,
      });
    }
  };

  return (
      <div style={{ padding: 24 }}>
        <h2>Add new active</h2>
        <Form layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input placeholder="Enter the name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="I.U. Claim" name="iuClaim">
                <Input placeholder="Enter I.U. Claim" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="VRN" name="vrn">
                <Input
                    type="number"
                    placeholder="Enter VRN"
                    addonAfter={
                      <Select
                          placeholder="Unit"
                          style={{ width: 90 }}
                          value={vrnUnit}
                          onChange={(value) => setVrnUnit(value)}
                      >
                        <Option value="mg">mg</Option>
                        <Option value="g">g</Option>
                      </Select>
                    }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="UL" name="ul">
                <Input
                    type="number"
                    placeholder="Enter UL"
                    addonAfter={
                      <Select
                          placeholder="Unit"
                          style={{ width: 90 }}
                          value={ulUnit}
                          onChange={(value) => setUlUnit(value)}
                      >
                        <Option value="mg/day">mg/day</Option>
                        <Option value="g/day">g/day</Option>
                      </Select>
                    }
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Active
            </Button>
          </Form.Item>
        </Form>
      </div>
  );
};

export default AddActiveForm;
