import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Divider, Form, Input, notification, Row, Select, Space, Spin} from 'antd';
import {FieldConfig, formConfig, Section} from '../models/productFormData';
import {InfoCircleOutlined, MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import './ProductForm.css';
import {useLocation} from 'react-router-dom';
import {selectorItemsApi} from '../apiCalls/selectorItemsApi';
import {productsApi} from '../apiCalls/productsApi';
import CheckboxList from '../components/CheckboxList';
import SelectComponent from '../components/Select';
import SectionTitle from '../components/SectionTitle';
import {useUser} from '../UserContext';

const UNITS_INPUTS_WITH = '300px'

const AddIngredientForm: React.FC = () => {
  const [sections, setSections] = useState<Section[]>(formConfig.form.sections);
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const location = useLocation();
  const [checkboxValues, setCheckboxValues] = useState<{[key: string]: boolean | undefined}>({});
  const [quantitySelectorValues, setQuantitySelectorValues] = useState<{[key: string]: string | undefined}>({});
  const [unitClaimValue, setUnitClaimValue] = useState(' ');

  const {user} = useUser();
  console.log('user', user);

  const handleCheckboxChange = (fieldName: string, checked: boolean) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [fieldName]: checked,
    }));
  };

  useEffect(() => {
    setIsLoading(true);

    const completeFields = async () => {
      try {
        const updatedSections = await Promise.all(
          sections.map(async (section) => {
            if (section.fieldsEndpoint) {
              try {
                const fields = await selectorItemsApi.getItemList(section.fieldsEndpoint);
                const inputs = fields.map((field) => ({
                  label: field.name,
                  name: field.name,
                  id: field.id,
                  obligatory: false,
                  disabled: false,
                  type: 'checkbox',
                })) as FieldConfig[];

                return {...section, fields: section.fields.concat(inputs)};
              } catch (error) {
                console.error(`Error fetching fields for section ${section.title}:`, error);
                return section;
              }
            }

            return section;
          }),
        );

        setSections(updatedSections);
      } catch (error) {
        console.error('Error completing fields:', error);
      } finally {
        setIsLoading(false);
      }
    };

    completeFields();
  }, []);

  useEffect(() => {
    const quantitySelector: {[key: string]: string} = {};

    sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.type === 'textInputNumberQuantitySelector' && field.options) {
          quantitySelector[field.name + 'Unit'] = field.options[0];
        }
      });
    });

    setQuantitySelectorValues(quantitySelector);
  }, []);

  const handleSubmit = async (values: any) => {
    const formattedValues: {[key: string]: any} = values;

    sections.forEach((section) => {
      if (section.fieldsEndpoint) {
        const sectionValues: {[key: string]: boolean} = {};

        section.fields.forEach((field) => {
          if (!field.id) return;

          sectionValues[field.id] = values[field.name];
          delete formattedValues[field.name];
        });

        if (section.name) {
          formattedValues[section?.name] = sectionValues;
        }
        section.fields.forEach((field) => {
          if (!field.id) return;
          delete formattedValues[field.name];
        });
      }
    });

    if (formattedValues.actives && Array.isArray(formattedValues.actives)) {
      formattedValues.actives = formattedValues.actives.map((active, index) => {
        const checkboxKey = `declarable${index}`;
        return {
          activeValue: active.activeValue,
          activeName: active.activeName,
          declarable: checkboxValues[checkboxKey] ?? false,
        };
      });
    }
    const formattedValuesWithUnits = {...formattedValues, ...quantitySelectorValues};

    try {
      await productsApi.setProduct(formattedValuesWithUnits);

      notification.success({
        message: 'Operación exitosa',
        description: 'Tu operación se ha completado con éxito.',
        duration: 3,
      });
    } catch (e) {
      notification.error({
        message: 'Error',
        description: 'Ocurrió un error, vuelva a intentar',
        duration: 3,
      });
    }
  };

  const renderForm = (sectionTitle: string, item: FieldConfig, itemsSizeInRow = 8) => {
    return (
        <Col key={item.label} xs={24} sm={12} md={itemsSizeInRow} lg={itemsSizeInRow}>
          <Form.Item
              className={item.type === 'checkbox' ? 'custom-checkbox-item' : 'custom-form-item'}
              style={{ marginBottom: item.type === 'checkbox' ? '8px' : '12px' }} // Ajusta el margen inferior
              label={item.type === 'checkbox' ? ' ' : item.label}
              name={item.type === 'checkbox' ? undefined : item.name}
              tooltip={
                item.tooltip
                    ? {
                      title: item.tooltip,
                      icon: <InfoCircleOutlined style={{ marginLeft: 10, color: 'black' }} />,
                    }
                    : undefined
              }
              rules={[
                {
                  required: item.obligatory,
                  message: `Please input your ${item.label}!`,
                },
              ]}
          >
            {renderFormItem(sectionTitle, item)}
          </Form.Item>
        </Col>
    );
  };

  const renderFormItem = (sectionTitle: string, item: FieldConfig) => {
    let unit = item.unit ? item.unit : undefined;

    if (item.name === 'vrn') {
      unit = unitClaimValue;
    }

    const inputStyle =
        sectionTitle === 'Nutritional Values' || sectionTitle === 'Microbiology'
            ? { width: UNITS_INPUTS_WITH }
            : {};

    switch (item.type) {
      case 'textInputNumber':
        return (
            <Input
                type="number"
                placeholder={`Enter ${item.label}`}
                addonAfter={unit}
                disabled={item.disabled}
                min={0}
                step={item.step ?? 0.000001}
                style={inputStyle}
            />
        );
      case 'textInputNumberQuantitySelector':
        return (
          <Input
            type="number"
            placeholder={`Enter ${item.label}`}
            addonAfter={
              <Select
                onSelect={(value) => {
                  setQuantitySelectorValues((prevValues) => ({
                    ...prevValues,
                    [item.name + 'Unit']: value,
                  }));
                }}
                defaultValue={item?.options ? item.options[0] : ''}
                style={{width: 90}}
              >
                {item?.options?.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            }
            style={inputStyle}
            disabled={item.disabled}
          />
        );
      case 'textInputString':
        return (
          <Input
            placeholder={`Enter ${item.label}`}
            addonAfter={item.unit ? item.unit : undefined}
            disabled={item.disabled}
            style={{
              fontStyle: item.cursive ? 'italic' : undefined,
              ...inputStyle
            }}
          />
        );
      case 'checkbox':
        return (
            <Checkbox>{item.label}</Checkbox>
        );
      case 'selector':
        return (
          <SelectComponent
            title={item?.label}
            onChange={(value) => {
              if (item.name === 'unitClaim') {
                setUnitClaimValue(value);
              }
            }}
            defaultValue={location?.state?.initialValues[item.label.toLowerCase()] ?? ''}
            endpoint={item.endpoint}
            postEndpoint={item.postEndpoint}
            postExtraValues={item.postExtraValues}
            canDoPostRequest={item.canDoPostRequest}
          ></SelectComponent>
        );
      case 'checkboxListOneOption':
        return <CheckboxList form={form} endpoint={item.endpoint!}></CheckboxList>;
    }
  };

  const renderFormCanAddRow = (
    section: Section,
    dynamicFields: any[],
    add: () => void,
    remove: (index: number) => void,
  ) => {
    const size = section.itemsSizeInRow ? section.itemsSizeInRow : 11;

    return (
        <div style={{ marginTop: 15 }}>
          {dynamicFields.map((dynamicField, index) => (
              <div style={{ display: section.itemsSizeInRow ? 'flex' : 'flex' }} key={dynamicField.key}>
                <Row style={{ width: '100%' }} gutter={16} key={dynamicField.key}>
                  {section.fields.map((field) => {
                    const fieldName = field.type === 'checkbox' ? field.name + index : field.name;

                    return (
                        <Col xs={24} sm={12} md={size} lg={size} key={field.label}>
                          <Form.Item
                              className={field.type === 'checkbox' ? 'custom-checkbox-item' : 'custom-form-item'}
                              style={{ marginBottom: field.type === 'checkbox' ? '4px' : '12px' }}
                              label={field.label + (section.name === 'actives' ? ' #' + (index + 1) : '')}
                              name={
                                field.type === 'checkbox'
                                    ? [dynamicField.name, fieldName]
                                    : [dynamicField.name, fieldName]
                              }
                              fieldKey={[dynamicField.fieldKey, field.label.toLowerCase()]}
                          >
                            {field.type === 'checkbox' ? (
                                <Checkbox
                                    checked={checkboxValues[fieldName] || false}
                                    onChange={(e) => handleCheckboxChange(fieldName, e.target.checked)}
                                >
                                  {field.label + (section.name === 'actives' ? ' #' + (index + 1) : '')}
                                </Checkbox>
                            ) : (
                                renderFormItem(section.title, field)
                            )}
                          </Form.Item>
                        </Col>
                    );
                  })}
                  <Divider
                      orientationMargin={0}
                      style={{
                        marginBottom: 10,
                        marginTop: 10,
                      }}
                  ></Divider>
                </Row>

            <MinusCircleOutlined style={{marginLeft: 10}} onClick={() => remove(index)} />
          </div>
        ))}

        <Form.Item style={{height: 50, marginTop: 10}}>
          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
            {section.name === 'actives' ? 'Add Active' : 'Add Row'}
          </Button>
        </Form.Item>
      </div>
    );
  };

  if (isLoading) {
    return (
      <Spin style={{justifyContent: 'center', flex: 1, marginTop: 150}} tip="Loading" size="large">
        <div className="content" />
      </Spin>
    );
  }

  const getInitialValues = () => {
    if (location?.state?.initialValues) {
      return location?.state?.initialValues;
    }

    const defaultValues: {[key: string]: any} = {};

    sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.defaultValue !== undefined) {
          defaultValues[field.label.toLowerCase()] = field.defaultValue;
        }
      });
    });

    return defaultValues;
  };

  return (
      <div className={'container'}>
        <h2 style={{ marginBottom: 50, marginLeft: -5 }}>Add New Ingredient</h2>
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={getInitialValues()}
        >
          {sections.map((section, sectionIndex) => (
              <div key={sectionIndex}>
                {section.title && (
                    <SectionTitle title={section.title} isSubtitle={section.isSubtitle} />
                )}

                {section.canAddRows ? (
                    <Form.List name={section.name ?? section.title}>
                      {(fields, { add, remove }) => renderFormCanAddRow(section, fields, add, remove)}
                    </Form.List>
                ) : (
                    <Row gutter={16}>
                      {section.fields.map((field) =>
                          renderForm(section.title, field, section.itemsSizeInRow),
                      )}
                    </Row>
                )}
              </div>
          ))}
          <Space style={{ marginTop: 16, marginBottom: 30 }}>
            <Button type="primary" htmlType="submit">
              Save Ingredient
            </Button>
          </Space>
        </Form>
      </div>
  );
};

export default AddIngredientForm;
